export const state = {
  mediaID: "",
  isCtrlKeyPressed: false,
  offsetX: 0,
  maybeDoubleClickDragging: false,
  isDown: false,
  scrollStartX: 0,
  scrollLeft: 0,
  maybeDoubleClickDraggingTimeout: null,
  startX: 0,
  seekX: 0,
  mouseX: 0,
};
